import { ButtonBase, Typography, useTheme } from "@mui/material"
import { useLayoutEffect, useMemo, useRef, useState } from "react"
import { trackEvent } from "../../../utils/mixpanel"
import { extraColors } from "../../../utils/theme"
import {
  getConfidenceFromJustification,
  type JustificationConfidence,
} from "../../Shared/AlertConfidence"
import {
  IntelligenceCard,
  type IntelligenceCardProps,
} from "./IntelligenceCard"

interface AlertCardProps extends IntelligenceCardProps {
  justification: string | undefined
  recommendation: string | undefined
}

const LINES_TO_SHOW = 3

export const AlertCard = ({
  alertLevel,
  title,
  justification,
  recommendation,
  date,
  className,
  sourceURL,
  id,
  threat,
  geotags,
}: AlertCardProps) => {
  const [expanded, setExpanded] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  // measure content height to determine if we need to show the "see more" button
  const [numLines, setNumLines] = useState(0)

  const justificationParsed: JustificationConfidence = useMemo(
    () => getConfidenceFromJustification(justification),
    [justification]
  )

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (!contentRef.current) return
      const contentHeight = contentRef.current.scrollHeight
      const lineHeight = parseInt(
        window.getComputedStyle(contentRef.current).lineHeight
      )
      const calculatedNumLines = Math.floor(contentHeight / lineHeight)
      setNumLines(calculatedNumLines)
    })

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const isOverflowing = numLines > LINES_TO_SHOW

  const handleShowMore = () => {
    setExpanded(!expanded)
    trackEvent("CLICK_ALERT_SEE_MORE", {
      title,
      link: sourceURL ?? "",
    })
  }

  return (
    <IntelligenceCard
      className={className}
      confidence={justificationParsed}
      date={date}
      id={id}
      threat={threat}
      geotags={geotags}
      title={title}
      alertLevel={alertLevel}
      sourceURL={sourceURL}
    >
      <>
        <Typography
          variant="small1"
          ref={contentRef}
          sx={{
            position: "relative",
            display: "block",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            lineHeight: "18px",
            height: expanded ? "auto" : `calc(18px * ${LINES_TO_SHOW})`,
          }}
        >
          <Typography variant="inherit" fontWeight={300}>
            <Typography variant="small1Bold">RECOMMENDATION</Typography> -{" "}
            {recommendation}
          </Typography>
          <Typography variant="inherit" fontWeight={300} paddingTop="8px">
            <Typography variant="small1Bold">SITUATION</Typography> -{" "}
            {justification}
          </Typography>
        </Typography>
        {isOverflowing && (
          <ButtonBase
            onClick={handleShowMore}
            disableRipple
            sx={{
              ...theme.typography.small1,
              color: extraColors.purpleMiddle,
              position: expanded ? "inline" : "absolute",
              bottom: 0,
              right: 0,
              background: "linear-gradient(90deg, transparent 0%, white 6px)",
              display: "inline",
              padding: "0 4px",
              paddingLeft: expanded ? 0 : "10px",
            }}
          >
            {expanded ? "show less" : "... show more"}
          </ButtonBase>
        )}
      </>
    </IntelligenceCard>
  )
}
