import { Divider, styled, Typography } from "@mui/material"
import type { AnalysisWithReferences, Category } from "@phc/common"
import type React from "react"
import { useMemo } from "react"
import { generatePath, Link } from "react-router-dom"
import analysisImg from "../../../assets/pngs/related-analysis.png"
import { ROUTES } from "../../../utils/constants"
import { dayjsUTC } from "../../../utils/helpers"
import { extraColors } from "../../../utils/theme"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"
import { getCriticalThreatByContentId } from "../../CriticalThreats/criticalThreatHelper"
import { CriticalThreatTag } from "../../CriticalThreats/CriticalThreatTag"
import { AnalysisLoadingItem } from "../../RelatedAnalyses/AnalysisLoadingCard"
import { CategoryTag } from "./CategoryTag"
import { SectionContainer } from "./Shared"

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  marginTop: 4,
}))

const ItemContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  marginBottom: "12px",
  gap: "12px",
}))

const Card = styled("div")({
  display: "flex",
  flexDirection: "column",
})

const Info = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 8,
})

const InfoContainer = styled("div")({
  borderRadius: 8,
  height: "100%",
  display: "grid",
  placeContent: "center",
  textAlign: "center",
  gap: 32,
})

const Img = styled("img")({
  width: "100%",
  height: "100%",
})

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3Bold,
  color: extraColors.medium,
  fontWeight: 600,
}))

const SectionContainerStyled = styled(SectionContainer)({
  marginTop: 22,
})

const LinkStyled = styled(Link)({
  textDecoration: "none",
})

const TagRow = styled("div")({
  display: "flex",
})

const CriticalThreatTagStyled = styled(CriticalThreatTag)({
  marginRight: 4,
})

export const RelatedAnalyses: React.FC<{
  locationId: string | undefined
}> = ({ locationId }) => {
  return (
    <Container>
      <Title>Related Analysis</Title>
      <SectionContainerStyled>
        <AnalysisList locationId={locationId} />
      </SectionContainerStyled>
    </Container>
  )
}

const AnalysisList: React.FC<{ locationId: string | undefined }> = ({
  locationId,
}) => {
  const { useRelatedAnalyses } = useCapServiceContext()
  const { data, isLoading, isError } = useRelatedAnalyses(locationId)

  if (isLoading) return <AnalysisLoading />

  if (!data || data.length === 0 || isError) return <NoRelatedAnalysis />

  return (
    <Card>
      {data.map((analysis, index: number) => (
        <div key={analysis._id}>
          <AnalysisItem analysis={analysis} />
          {data.length - index > 1 && (
            <Divider sx={{ margin: "24px 0", color: extraColors.light }} />
          )}
        </div>
      ))}
    </Card>
  )
}

const AnalysisItem: React.FC<{ analysis: AnalysisWithReferences }> = ({
  analysis,
}) => {
  const { useCriticalThreats } = useCapServiceContext()
  const { data } = useCriticalThreats()
  const relatedCriticalThreat = useMemo(() => {
    return getCriticalThreatByContentId(analysis._id, data)
  }, [analysis._id, data])

  return (
    <ItemContainer>
      <Info>
        <TagRow>
          <CriticalThreatTagStyled
            relatedCriticalThreat={relatedCriticalThreat}
          />
          <Categories categories={analysis.categories} />
        </TagRow>
        <Typography
          variant="small1"
          color={extraColors.hint}
          sx={{ paddingLeft: 1 }}
        >
          {dayjsUTC(analysis.updatedAt).fromNow()}
        </Typography>
      </Info>
      <LinkStyled
        to={generatePath(ROUTES.ANALYSIS_POST, {
          slug: analysis.slug?.current || "",
        })}
      >
        <Typography variant="body2Bold" color={extraColors.status.teal}>
          {analysis.title}
        </Typography>
      </LinkStyled>
    </ItemContainer>
  )
}

const Categories: React.FC<{
  categories: Category[] | undefined
}> = ({ categories }) => {
  return (
    <>
      {categories?.map(cat => {
        if (cat.title) {
          return <CategoryTag key={cat.title} category={cat.title} />
        }
      })}
    </>
  )
}

const NoRelatedAnalysis: React.FC = () => {
  return (
    <InfoContainer>
      <Img src={analysisImg} />
      <Typography variant="body1Bold" style={{ color: extraColors.disabled }}>
        No Related Analysis
      </Typography>
    </InfoContainer>
  )
}

const AnalysisLoading: React.FC = () => {
  return (
    <Card>
      {Array.from({ length: 3 }, (_, i) => (
        <AnalysisLoadingItem key={i} />
      ))}
    </Card>
  )
}
