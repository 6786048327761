import EastIcon from "@mui/icons-material/East"
import MovingIcon from "@mui/icons-material/Moving"
import { Risk_Trend } from "@phc-health/connect-query"
import type { IconSize } from "../../icons/IconSize"
import SvgSlightDown from "../../icons/RiskArrows/Decreasing"
import SvgSlightUp from "../../icons/RiskArrows/Increasing"
import SvgSteady from "../../icons/RiskArrows/Persistant"

interface TrendProps {
  trend?: Risk_Trend
  color: string
  size?: IconSize
}

export const TrendSVG: React.FC<TrendProps> = ({ trend, color, size }) => {
  switch (trend) {
    case Risk_Trend.DECREASING:
    case Risk_Trend.DECREASING_RAPIDLY:
      return <SvgSlightDown size={size} fill={color} />
    case Risk_Trend.INCREASING:
    case Risk_Trend.INCREASING_RAPIDLY:
      return <SvgSlightUp size={size} fill={color} />
    case Risk_Trend.PERSISTENT:
      return <SvgSteady size={size} fill={color} />
    default:
      return null
  }
}

export const TrendIndicator: React.FC<{ trend?: Risk_Trend; size: number }> = ({
  trend,
  size,
}) => {
  switch (trend) {
    case Risk_Trend.DECREASING:
    case Risk_Trend.DECREASING_RAPIDLY:
      return <MovingIcon sx={{ height: size, transform: "scale(1, -1)" }} />
    case Risk_Trend.INCREASING:
    case Risk_Trend.INCREASING_RAPIDLY:
      return <MovingIcon sx={{ height: size }} />
    case Risk_Trend.PERSISTENT:
      return <EastIcon sx={{ height: size }} />
    default:
      return null
  }
}
