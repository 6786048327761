import { defineArrayMember, defineField, defineType } from "sanity"
import { category } from "./category"
import { geotag, geotags } from "./geotag"
import { sharedContent, sharedOrderings, sharedPreview } from "./shared"

export const AlertLevel = {
  WARNING: "warning",
  WATCH: "watch",
  ADVISORY: "advisory",
} as const

export type AlertLevel = (typeof AlertLevel)[keyof typeof AlertLevel]

export const isAlertLevel = (value: any): value is AlertLevel => {
  return (
    value === AlertLevel.WARNING ||
    value === AlertLevel.WATCH ||
    value === AlertLevel.ADVISORY
  )
}

export const alert = defineType({
  type: "document",
  name: "alert",
  title: "Alert",
  fieldsets: [
    {
      name: "updatedAt",
      title: "Updated Date",
      description:
        "If published date is set, then this date will automatically update",
      options: {
        collapsible: true,
        collapsed: true,
        columns: 2,
      },
    },
  ],
  fields: [
    ...sharedContent,
    defineField({
      name: "link",
      type: "url",
      validation: Rule => Rule.required(),
    }),
    defineField({
      name: "categories",
      title: "Category",
      type: "array",
      validation: Rule => Rule.required().length(1),
      of: [
        defineArrayMember({
          type: "reference",
          to: [{ type: category.name }],
        }),
      ],
    }),
    /** @deprecated Use geotags */
    {
      ...geotag,
      hidden: true,
      deprecated: {
        reason: "Use geotags instead",
      },
    },
    geotags,
    defineField({
      name: "alertLevel",
      type: "string",
      validation: Rule => Rule.required(),
      options: {
        list: [
          { title: "Warning", value: AlertLevel.WARNING },
          { title: "Watch", value: AlertLevel.WATCH },
          { title: "Advisory", value: AlertLevel.ADVISORY },
        ],
      },
    }),
    defineField({
      name: "recommendation",
      type: "text",
    }),
    defineField({
      name: "justification",
      type: "text",
    }),
    defineField({
      name: "public",
      description: "Surface alert on the public website",
      type: "boolean",
      initialValue: false,
    }),
    defineField({
      name: "generated",
      description: "Automatically generated alert",
      type: "boolean",
      initialValue: false,
      readOnly: true,
    }),
  ],
  orderings: sharedOrderings,
  preview: sharedPreview,
})
