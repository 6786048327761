import { styled } from "@mui/material"
import type React from "react"
import { useRef } from "react"
import { useLocationId } from "../../../hooks/useLocationId.js"
import { LEGAL_STATEMENT } from "../../../utils/constants.js"
import { PrintTemplate, type PrintHandle } from "../../PrintTemplate.js"
import { RelatedAlerts } from "./RelatedAlerts.js"
import { RelatedAnalyses } from "./RelatedAnalyses.js"
import { RiskDetails } from "./RiskDetails.js"

const DetailsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr minmax(350px, 1fr)",
  gap: 24,
  marginBottom: 24,
  "@media print": {
    gridTemplateColumns: "unset",
  },
  [theme.breakpoints.down("md")]: {
    gap: 12,
    gridTemplateColumns: "unset",
    padding: 6,
  },
}))

export const LocationRiskDetails: React.FC = () => {
  const locationId = useLocationId()
  const printRef = useRef<PrintHandle>(null)

  // TODO: Add loading state
  if (!locationId) return null

  return (
    <PrintTemplate ref={printRef} legalStatement={LEGAL_STATEMENT}>
      <DetailsContainer>
        <div>
          <RiskDetails locationId={locationId} printRef={printRef} />
        </div>
        <div>
          <RelatedAlerts locationId={locationId} />
          <RelatedAnalyses locationId={locationId} />
        </div>
      </DetailsContainer>
    </PrintTemplate>
  )
}
