import { Box, styled, Typography } from "@mui/material"
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useReactToPrint } from "react-to-print"
import { useAppLogo } from "../hooks/useAppLogo"

const PrintContainer = styled(Box)({
  "@media print": {
    /* these properties default background graphics **/
    webKitPrintColorAdjust: "exact",
    colorAdjust: "exact",
    printColorAdjust: "exact",
    "@page": {
      size: "8.5in 11in",
      margin: "8mm 0mm 0mm 0mm",
      border: "none !important",
    },
  },
})

export interface PrintHandle {
  handlePrintClick: () => void
}

interface PrintTemplateProps {
  children: JSX.Element
  legalStatement?: string
}

export const PrintTemplate = forwardRef<PrintHandle, PrintTemplateProps>(
  ({ children, legalStatement }, ref) => {
    const [isPrinting, setIsPrinting] = useState<boolean>(false)
    const componentRef = useRef(null)
    const onBeforeGetContentResolve = useRef<((value?: any) => void) | null>(
      null
    )
    const appLogo = useAppLogo()

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => {
        setIsPrinting(false)
      },
      onBeforeGetContent: () => {
        return new Promise(resolve => {
          setIsPrinting(true)
          onBeforeGetContentResolve.current = resolve
        })
      },
    })

    useImperativeHandle(ref, () => ({
      handlePrintClick() {
        handlePrint()
      },
    }))

    useEffect(() => {
      if (isPrinting && onBeforeGetContentResolve.current) {
        onBeforeGetContentResolve.current() // Resolves the Promise in `onBeforeGetContent`
      }
    }, [isPrinting, onBeforeGetContentResolve])

    return (
      <PrintContainer
        ref={componentRef}
        margin={isPrinting ? "0 20px 20px 20px" : ""}
      >
        {isPrinting && (
          <Box display="flex" justifyContent="center" padding={2}>
            <img src={appLogo?.full} alt={appLogo?.altText} height={40} />
          </Box>
        )}

        {children}
        {isPrinting && legalStatement && (
          <Box paddingTop={5}>
            <Typography variant="h3">Confidential and Proprietary</Typography>
            <Typography variant="body2" fontStyle={"italic"}>
              {legalStatement}
            </Typography>
          </Box>
        )}
      </PrintContainer>
    )
  }
)

PrintTemplate.displayName = "PrintTemplate"
