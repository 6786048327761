import imageUrlBuilder from "@sanity/image-url"
import type { SanityImageWithAssetStub } from "@sanity/image-url/lib/types/types"
import type { PortableTextTextBlock, Reference, SanityClient } from "sanity"
import { isPortableTextTextBlock } from "sanity"
import { GeotagType } from "./cms-schema/geotag"
import type { Category } from "./cms-schema/sanity.types"
import type { Geotag } from "./cms-schema/sanityTypesManual"
import { SANITY_PROJECT_ID } from "./constants"
import { getMapboxGeocodeResult } from "./helpers"
import { getIdsFromGeocoderResult } from "./mapboxTilesetQuery"

export const filterBlockArray = (blocks: unknown[]): PortableTextTextBlock[] =>
  blocks.flatMap(block => (isPortableTextTextBlock(block) ? block : []))

export const getFootnotes = (
  blocks: Partial<PortableTextTextBlock>[] | undefined
) => {
  const footnotes = blocks
    ?.flatMap(c => (isPortableTextTextBlock(c) ? c : []))
    .flatMap(c => c.markDefs?.filter(m => m._type === "footnote"))
  const footnotesIds = footnotes?.map(f => f?._key)

  // Find where footnote occurs in the document
  const footnotesOrder = blocks
    ?.flatMap(block => block.children?.flatMap(c => c.marks))
    .filter(
      markId => typeof markId === "string" && footnotesIds?.includes(markId)
    )
    .map(footnoteId => footnotes?.find(f => f?._key === footnoteId))
    .filter(
      (footnote, index, self) =>
        index === self.findIndex(f => f?.reference === footnote?.reference)
    )

  return {
    footnotes,
    footnotesOrder,
  }
}

export const categoryByTitleQuery = (title: string) => ({
  query: /* groq */ `*[_type == "category" && title == $title][0]`,
  params: {
    title,
  },
})

export const getCMSCategory = async (
  client: SanityClient,
  category: string
) => {
  if (!category) {
    throw new Error(`Please include a category`)
  }
  const { query, params } = categoryByTitleQuery(category)
  const matchedCategory = await client.fetch<Category | undefined>(
    query,
    params
  )
  console.log("matchedCategory", matchedCategory)
  if (!matchedCategory?._id) {
    throw new Error(`Category not found in CMS: "${category}"`)
  }
  return {
    _type: "reference",
    _ref: matchedCategory._id,
  } satisfies Reference
}

export const createCMSGeotag = async ({
  locationsStr,
  accessToken,
}: {
  locationsStr: string
  accessToken: string | undefined
}) => {
  if (!locationsStr) {
    throw new Error(`Please include a location to search for`)
  }
  try {
    const locations = locationsStr
      .split(";")
      .map(l => l.trim())
      .filter(Boolean)
    const results = locations.map(async singleLocation => {
      if (!singleLocation) {
        throw new Error(`Location not set`)
      }
      const result = await getMapboxGeocodeResult({
        query: singleLocation,
        accessToken,
      })

      if (result?.features.length === 0) {
        throw new Error(`No results found for ${singleLocation}`)
      }
      if (!result?.features[0]) {
        throw new Error(`No feature found for ${singleLocation}`)
      }
      const [feature, parent] = await getIdsFromGeocoderResult(
        { result: result.features[0] },
        accessToken
      )

      const locationId = feature?.properties.location_code
      if (!locationId) {
        throw new Error(`Location ID not found for ${singleLocation}`)
      }
      const locationName =
        feature.properties.location_name ??
        `${
          feature.properties.location_code ?? "<location_code missing>"
        }: Name not found`
      // try to use direct parent name as subtitle
      const subtitle = parent?.properties.location_name
      return {
        _key: locationId,
        _type: "geotag",
        id: locationId,
        locationId: locationId,
        centroid: feature.properties.centroid,
        countryCode: feature.properties.country_code?.toLowerCase(),
        geotagType: GeotagType.ADMIN_BOUNDARY,
        name: locationName,
        subtitle,
      } satisfies Geotag
    })
    return await Promise.all(results)
  } catch (e) {
    console.error(e)
    if (e instanceof Error) {
      throw new Error(`Error getting geotag for ${locationsStr}: ${e.message}`)
    }
    throw new Error(`Error getting geotag for ${locationsStr}`)
  }
}

/**
 * Keep this type in sync with image in `blockContent` in phc-cms
 */
export interface BlockContentImage extends SanityImageWithAssetStub {
  alt: string
  width?: string
  height?: string
  align?: string
}

export const cmsUrlBuilder = (dataset: string) => {
  return imageUrlBuilder({
    dataset,
    projectId: SANITY_PROJECT_ID,
  })
}
