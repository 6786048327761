import { AddIcon, CloseIcon } from "@sanity/icons"
import { Button, Card, Grid, Inline } from "@sanity/ui"
import type React from "react"
import { useCallback, useState } from "react"
import {
  set,
  useClient,
  useFormValue,
  type ArrayOfObjectsInputProps,
} from "sanity"
import { useMapboxCss } from "./hooks/useMapboxCss"

import type { Geotag } from "../.."
import { GeotagMap } from "./GeotagMap"

export const GeoCodeInput: React.FC<
  ArrayOfObjectsInputProps<Geotag & { _key: string }>
> = props => {
  useMapboxCss()
  const { value: geotags = [], onChange } = props
  const client = useClient({
    apiVersion: "2024-09-01",
  })
  const docId = useFormValue(["_id"]) as string
  const docType = useFormValue(["_type"]) as string
  const isAlert = docType === "alert"
  const deprecatedGeotag = useFormValue(["geotag"]) as Geotag | undefined
  const [searchResultGeotags, setSearchResults] = useState<Geotag[]>([])
  const selectedLocations = searchResultGeotags

  const addGeoTag = useCallback(
    (locationId: string) => {
      // Don't add if already exists
      if (geotags.find(l => l.id === locationId)) return
      const selectedLocation = selectedLocations.find(l => l.id === locationId)
      if (!selectedLocation) {
        console.error(
          `Could not find selected location with id ${locationId} in search results`
        )
        return
      }
      // this will organize by country, and nest regions underneath
      const newValues: Geotag[] = [...geotags, selectedLocation].sort((a, b) =>
        a.id && b.id ? a.id.localeCompare(b.id) : 0
      )
      onChange(set(newValues))

      // maintain backwards compatibility with deprecated geotag field
      isAlert &&
        client
          .patch(docId)
          .set({ geotag: selectedLocation })
          .commit()
          .catch(console.error)
    },
    [client, docId, geotags, isAlert, onChange, selectedLocations]
  )

  const removeGeotagByLocationId = useCallback(
    (locationId: string) => {
      onChange(set(geotags.filter(l => l.id !== locationId)))
      if (isAlert && locationId === deprecatedGeotag?.id) {
        client.patch(docId).unset(["geotag"]).commit().catch(console.error)
      }
    },
    [onChange, geotags, isAlert, deprecatedGeotag?.id, client, docId]
  )

  return (
    <Card border padding={4}>
      <GeotagMap
        setSearchResults={setSearchResults}
        selectedLocations={geotags}
      />
      <Card padding={2} style={{ textAlign: "center" }}>
        <Grid gap={2}>
          {geotags.map(location => (
            <Button
              key={location.id}
              fontSize={[2, 2, 3]}
              iconRight={CloseIcon}
              padding={2}
              text={
                deprecatedGeotag?.id === location.id
                  ? `**${location.name ?? "<name not set>"}**`
                  : location.name
              }
              tone="positive"
              onClick={() =>
                location.id && removeGeotagByLocationId(location.id)
              }
              style={{
                width: "fit-content",
                marginLeft: (location.id?.length ?? 0) <= 3 ? 0 : 16,
              }}
            />
          ))}
        </Grid>
      </Card>
      <hr />

      <Card padding={4} style={{ textAlign: "center" }}>
        <Inline space={2}>
          {selectedLocations.map(location => {
            if (!location.id) {
              console.error("location missing id", location)
              return null
            }
            const exists = geotags.find(l => l.id === location.id)
            return (
              <Button
                key={location.id}
                fontSize={[2, 2, 3]}
                icon={exists ? CloseIcon : AddIcon}
                padding={[3, 3, 4]}
                text={location.name}
                tone={exists ? "default" : "primary"}
                onClick={
                  exists
                    ? () => location.id && removeGeotagByLocationId(location.id)
                    : () => location.id && addGeoTag(location.id)
                }
              />
            )
          })}
        </Inline>
      </Card>
    </Card>
  )
}
