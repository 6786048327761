import { Dialog } from "@mui/material"
import Button from "@mui/material/Button"
import type * as React from "react"
import { AbsenteeismEmailExample } from "./Absenteeism/AbsenteeismEmailExample"
import { ExpertInsightsEmailExamples } from "./ExpertInsights/ExpertInsightsEmailExample/ExpertInsightsEmailExample"
import { RiskNotificationsEmailExample } from "./RiskIncrease/RiskNotificationsEmailExample"

type ExampleContentType = "expert insights" | "risk" | "absenteeism"

interface EmailExampleDialogProps {
  type: ExampleContentType
  dialogIsOpen: boolean
  setDialogIsOpen: (newState: boolean) => void
}

export const EmailExampleDialog: React.FC<EmailExampleDialogProps> = ({
  type,
  dialogIsOpen,
  setDialogIsOpen,
}) => {
  return (
    <Dialog
      onClose={() => setDialogIsOpen(false)}
      open={dialogIsOpen}
      PaperProps={{
        style: {
          maxHeight: "100%",
          maxWidth: "500px",
          height: "fit-content",
          overflow: "auto",
          width: "100%",
          minWidth: "100px",
        },
      }}
    >
      {getExampleContent(type)}
      <CloseButton setDialogIsOpen={setDialogIsOpen} />
    </Dialog>
  )
}

const CloseButton: React.FC<{
  setDialogIsOpen: (newState: boolean) => void
}> = ({ setDialogIsOpen }) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={() => setDialogIsOpen(false)}
      sx={{
        padding: "7px 20px",
        width: "fit-content",
        marginTop: "20px",
        alignSelf: "end",
      }}
    >
      Close
    </Button>
  )
}

function getExampleContent(type: ExampleContentType) {
  switch (type) {
    case "risk":
      return <RiskNotificationsEmailExample />
    case "absenteeism":
      return <AbsenteeismEmailExample />
    default:
      return <ExpertInsightsEmailExamples />
  }
}
