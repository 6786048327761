import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import { bbox as turfBbox } from "@turf/bbox"
import { circle } from "@turf/circle"
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
} from "react"
import {
  Map,
  Marker,
  type LngLatBoundsLike,
  type MapEvent,
  type MapRef,
} from "react-map-gl"
import { getCentroidCoordinates, getTileSetResponse } from "../../helpers"
import { determineSourceLayerContext, mapboxConfig } from "../../mapboxConfig"
import {
  getIdsFromGeocoderResult,
  type GeocoderResult,
} from "../../mapboxTilesetQuery"
import {
  MAPBOX_STYLE,
  MapSourceLayers,
  findFeatureBoundingBox,
} from "../../shared-map"
import { GeotagType } from "../geotag"
import type { Geotag } from "../sanityTypesManual"

type GeocodeFeature = Awaited<
  ReturnType<typeof getIdsFromGeocoderResult>
>[number]

const resetFeatureState = (map: mapboxgl.Map | undefined) => {
  const allMapboxLayers = map?.getStyle()?.layers
  if (!map || !allMapboxLayers) return
  // Iterate over each layer
  allMapboxLayers.forEach(layer => {
    // Check if the layer is a vector layer
    if (layer.type === "fill" || layer.type === "line") {
      // Get the source and sourceLayer from the layer
      const { source, "source-layer": sourceLayer } = layer

      if (!source || !sourceLayer) return

      if (typeof source === "object") {
        console.log("source unhandled, is an object", source)
        return
      }

      // Query the features of the layer
      const features = map
        .querySourceFeatures(source, {
          sourceLayer,
        })
        .filter(f => f.properties?.location_code)

      // Iterate over each feature
      features.forEach(feature => {
        if (!feature.id) return
        // Reset the feature state
        map.setFeatureState(
          {
            source: source.toString(),
            sourceLayer,
            id: feature.id,
          },
          { fillColor: "transparent" }
        )
      })
    }
  })
}

const setFillColor = (
  map: mapboxgl.Map | undefined,
  geotag: Geotag | undefined
) => {
  if (!geotag) return
  const context = determineSourceLayerContext(geotag.id)
  const layer = mapboxConfig.allBySource
    .get(geotag.countryCode ?? "")
    ?.find(l => l.context === context)
  if (!layer || !geotag.id) return
  if (!map?.isSourceLoaded(layer.sourceInfo.source)) return
  map.setFeatureState(
    {
      source: layer.sourceInfo.source,
      sourceLayer: layer.id,
      id: geotag.id,
    },
    { fillColor: "#a55a55" }
  )
}

const getCurrentFeature = async (
  currentGeotag: Geotag,
  coordinates: { lng: number; lat: number }
) => {
  if (!currentGeotag.countryCode) return undefined
  const response = await getTileSetResponse(
    coordinates,
    import.meta.env.SANITY_CMS_MAPBOX_TOKEN,
    currentGeotag.countryCode
  )
  const feature = response?.features.find(
    f => f.properties.location_code?.toString() === currentGeotag.id
  )
  return feature
}

const zoomToGeotag = async (
  map: mapboxgl.Map,
  currentGeotag: Geotag,
  coordinates: { lng: number; lat: number }
) => {
  const feature = await getCurrentFeature(currentGeotag, coordinates)
  if (!feature) return
  const bbox = findFeatureBoundingBox(feature)
  if (!bbox) return
  map.fitBounds(bbox, {
    padding: 50,
  })
}

const makeMarkerRadiusGeometry = (
  coordinates: { lat: number; lng: number } | undefined,
  radius: number
) =>
  circle(coordinates ? [coordinates.lng, coordinates.lat] : [0, 0], radius, {
    units: "miles",
  })

export const GeotagMap: FC<{
  setSearchResults: (geotags: Geotag[]) => void
  selectedLocations?: Geotag[]
}> = ({ setSearchResults, selectedLocations }) => {
  const firstGeotag = selectedLocations?.[0]
  const mapRef = useRef<MapRef>(null)
  const [firstSymbolId, setFirstSymbolId] = useState<string | undefined>()

  const coordinates = useMemo(
    () => getCentroidCoordinates(firstGeotag?.centroid),
    [firstGeotag?.centroid]
  )

  const initialBounds = (): LngLatBoundsLike | undefined => {
    if (
      firstGeotag?.geotagType === GeotagType.POINT &&
      firstGeotag.radius &&
      firstGeotag.centroid
    ) {
      const coords = getCentroidCoordinates(firstGeotag.centroid)
      const radius = makeMarkerRadiusGeometry(coords, firstGeotag.radius)
      const bbox = turfBbox(radius)
      return [bbox[0], bbox[1], bbox[2], bbox[3]]
    }
    return undefined
  }

  useEffect(() => {
    const map = mapRef.current?.getMap()
    selectedLocations?.forEach(location => {
      if (location.geotagType === GeotagType.ADMIN_BOUNDARY) {
        setFillColor(map, location)
      }
    })
    return () => {
      resetFeatureState(map)
    }
  }, [selectedLocations])

  const onResult = useCallback(
    (evt: GeocoderResult, features: GeocodeFeature[]) => {
      const { result } = evt
      if (!result) return
      const feature = features[0]
      const location =
        result.center ||
        (result.geometry?.type === "Point"
          ? result.geometry.coordinates
          : undefined)
      if (!location?.[0] || !location[1]) {
        console.error("no location found in result", result)
        return
      }
      const countryCode =
        result.context?.find(context => context.id.includes("country"))
          ?.short_code ??
        // country results don't come back with context, just use properties.short_code
        result.properties.short_code
      if (feature) {
        const names = features.map((d, i) => {
          // try and set set parent name as subtitle
          const subtitle = features[i + 1]?.properties.location_name
          return {
            _type: "geotag" as const,
            _key: d.properties.location_code?.toString() ?? "",
            geotagType: GeotagType.ADMIN_BOUNDARY,
            id: d.properties.location_code?.toString() ?? "",
            locationId: d.properties.location_code?.toString() ?? "",
            name: d.properties.location_name,
            subtitle,
            centroid: d.properties.centroid,
            countryCode,
          } satisfies Geotag
        })
        setSearchResults(names)
      }
    },
    [setSearchResults]
  )

  // This is to get around a closure bug in the geocoder event handler
  const onResultRef = useRef(onResult)
  useEffect(() => {
    onResultRef.current = onResult
  }, [onResult])

  const geocoderRef = useRef(
    new MapboxGeocoder({
      accessToken: import.meta.env.SANITY_CMS_MAPBOX_TOKEN,
      marker: false,
    })
  )

  const onMapLoad = useCallback(
    (e: MapEvent) => {
      const map = e.target
      map.addControl(geocoderRef.current)
      geocoderRef.current.on("result", (evt: GeocoderResult) => {
        const setFeatureData = async () => {
          const features = await getIdsFromGeocoderResult(
            evt,
            import.meta.env.SANITY_CMS_MAPBOX_TOKEN
          )
          onResultRef.current(evt, features)
        }
        setFeatureData().catch(console.error)
      })
      geocoderRef.current.on("clear", () => {
        setSearchResults([])
      })

      const allMapboxLayers = map.getStyle()?.layers
      if (!allMapboxLayers) return
      // Find the index of the first symbol layer in the map style.
      for (const layer of allMapboxLayers) {
        if (layer.type === "symbol") {
          setFirstSymbolId(layer.id)
          break
        }
      }
      selectedLocations?.forEach(location => {
        if (location.geotagType === GeotagType.ADMIN_BOUNDARY) {
          setFillColor(map, location)
        }
      })
      firstGeotag &&
        coordinates &&
        zoomToGeotag(map, firstGeotag, coordinates).catch(console.error)
    },
    [coordinates, firstGeotag, selectedLocations, setSearchResults]
  )

  return (
    <div
      style={{
        height: 500,
        width: "100%",
      }}
    >
      <Map
        mapboxAccessToken={import.meta.env.SANITY_CMS_MAPBOX_TOKEN}
        mapStyle={MAPBOX_STYLE}
        initialViewState={{
          longitude: coordinates?.lng,
          latitude: coordinates?.lat,
          bounds: initialBounds(),
          fitBoundsOptions: {
            padding: 50,
          },
        }}
        ref={mapRef}
        onLoad={onMapLoad}
      >
        <MapSourceLayers firstSymbolId={firstSymbolId} />
        {selectedLocations?.map(location => {
          const coords = getCentroidCoordinates(location.centroid)
          if (!coords) return null
          return (
            <Marker
              key={location.id}
              longitude={coords.lng}
              latitude={coords.lat}
            />
          )
        })}
      </Map>
    </div>
  )
}
