import type React from "react"
import type { SanityDocument, ValidationContext } from "sanity"
import { defineField } from "sanity"
import { GeoCodeInput } from "./components/GeoCodeInput"

export const GeotagType = {
  ADMIN_BOUNDARY: "adminBoundary",
  POINT: "point",
  POLYGON: "polygon",
} as const
export type GeotagType = (typeof GeotagType)[keyof typeof GeotagType]

interface GeotagValidationContext extends ValidationContext {
  document?: SanityDocument & {
    geotag?: {
      geotagType?: GeotagType
    }
  }
}

export const geotag = defineField({
  name: "geotag",
  type: "object",
  validation: Rule =>
    Rule.required().custom((doc, context: GeotagValidationContext) => {
      if (
        context.document?.geotag?.geotagType === GeotagType.ADMIN_BOUNDARY &&
        !doc?.id
      ) {
        return "Location Ids are required for admin boundaries"
      }
      if (
        context.document?.geotag?.geotagType === GeotagType.POINT &&
        !doc?.centroid
      ) {
        return "Centroid is required for points"
      }
      if (context.document?.geotag?.geotagType === GeotagType.POLYGON) {
        return "Polygons are not yet supported"
      }
      return true
    }),
  fields: [
    defineField({
      name: "geotagType",
      title: "Type",
      type: "string",
      // todo: migrate old geotags to adminBoundary, and make field required
      validation: Rule => Rule.required(),
      options: {
        list: [
          {
            title: "Admin Boundary",
            value: GeotagType.ADMIN_BOUNDARY,
          },
          {
            title: "Point",
            value: GeotagType.POINT,
          },
          {
            title: "Polygon",
            value: GeotagType.POLYGON,
          },
        ],
      },
    }),
    defineField({
      name: "id",
      title: "ID",
      type: "string",
      validation: Rule => Rule.required(),
    }),
    defineField({
      name: "locationId",
      title: "Location Id",
      type: "string",
      description: "PHC location id context",
      validation: Rule => Rule.required(),
    }),
    defineField({
      name: "name",
      title: "Name",
      type: "string",
    }),
    defineField({
      name: "subtitle",
      title: "Subtitle",
      type: "string",
    }),
    // TODO: either set from geo service or find way to make consistent with admin boundaries
    defineField({
      name: "centroid",
      title: "Centroid",
      type: "string",
    }),
    defineField({
      name: "radius",
      title: "Radius (miles)",
      type: "number",
    }),
    defineField({
      name: "countryCode",
      title: "Country Code",
      type: "string",
      description: "Needed for tileset queries",
    }),
    // TODO: add once we can save the geometry of admin boundaries
    // defineField({
    //   name: "geometry",
    //   title: "Geometry",
    //   type: "string",
    //   description: "GeoJSON geometry",
    // }),
  ],
})

const UntypedGeoCodeInput = GeoCodeInput as React.FC

export const geotags = defineField({
  name: "geotags",
  type: "array",
  components: {
    input: UntypedGeoCodeInput,
  },
  of: [geotag],
})
