import { Typography, styled } from "@mui/material"
import type { AlertLevel } from "@phc/common"
import type React from "react"
import { useMemo } from "react"
import { Marker } from "react-map-gl"
import SvgAlertIconAdvisory from "../../../assets/svg-components/AlertIconAdvisory"
import SvgAlertIconWarning from "../../../assets/svg-components/AlertIconWarning"
import SvgAlertIconWatch from "../../../assets/svg-components/AlertIconWatch"
import { useMapContext } from "../../../contexts/MapContext"
import { extraColors } from "../../../utils/theme"
import { useCapServiceContext } from "../../CriticalThreats/capServiceContext"
import { useMarkerHighlightHover } from "./hooks/useAlertHighlightSelected"

const AlertCount = styled(Typography)(() => ({
  position: "absolute",
  top: -8,
  left: 12,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
  // white circle background
  "&::before": {
    content: '""',
    width: 16,
    height: 16,
    borderRadius: "50%",
    background: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: -1,
    boxShadow: `0px 9px 12px 0px ${extraColors.shadow}`,
  },
}))

const AlertIcon: React.FC<
  {
    alertLevel: AlertLevel
  } & React.HTMLAttributes<SVGElement>
> = ({ alertLevel, ...props }) => {
  switch (alertLevel) {
    case "advisory":
      return <SvgAlertIconAdvisory {...props} />
    case "watch":
      return <SvgAlertIconWatch {...props} />
    case "warning":
      return <SvgAlertIconWarning {...props} />
  }
}

export interface AlertMarkerProps {
  alertId: string
  lng: number
  lat: number
  locationId: string
}

export const AlertMarker: React.FC<AlertMarkerProps> = ({
  alertId,
  lat,
  lng,
}) => {
  const { setClusterAlertsIds } = useMapContext()
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()
  const { setHoverFeatureState } = useMarkerHighlightHover(alertId)

  const alert = useMemo(
    () => alerts?.find(a => a._id === alertId),
    [alertId, alerts]
  )

  if (!alert?.alertLevel) return null

  return (
    <Marker
      longitude={lng}
      latitude={lat}
      anchor="center"
      style={{ cursor: "pointer" }}
      onClick={e => {
        e.originalEvent.stopPropagation()
        setClusterAlertsIds([alertId])
      }}
    >
      <AlertIcon
        alertLevel={alert.alertLevel}
        onPointerMove={() => {
          setHoverFeatureState(true)
        }}
        onPointerLeave={() => {
          setHoverFeatureState(false)
        }}
      />
    </Marker>
  )
}

export interface AlertClusterMarkerProps {
  cluster_id: number
  lng: number
  lat: number
  alertIds: string[]
}

export const AlertClusterMarker: React.FC<AlertClusterMarkerProps> = ({
  lng,
  lat,
  alertIds,
}) => {
  const { setClusterAlertsIds } = useMapContext()
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()

  const { setHoverFeatureState } = useMarkerHighlightHover(alertIds)
  const clusterAlerts = useMemo(() => {
    if (!alerts) return []
    return alertIds.map(a => alerts.find(alert => alert._id === a))
  }, [alertIds, alerts])

  const uniqueAlertLevels = useMemo(() => {
    return Array.from(
      new Set(clusterAlerts.flatMap(a => (a?.alertLevel ? [a.alertLevel] : [])))
    )
      .sort()
      .reverse()
  }, [clusterAlerts])

  if (!alerts) return
  const uniqueIdCount = new Set(alertIds).size

  return (
    <Marker
      longitude={lng}
      latitude={lat}
      anchor="center"
      style={{ cursor: "pointer" }}
      onClick={e => {
        e.originalEvent.stopPropagation()
        setClusterAlertsIds(alertIds)
      }}
    >
      <div
        onPointerMove={() => {
          setHoverFeatureState(true)
        }}
        onPointerLeave={() => {
          setHoverFeatureState(false)
        }}
      >
        {uniqueAlertLevels.map((level, i) => (
          <AlertIcon
            key={level}
            alertLevel={level}
            style={{
              position: "absolute",
              top: "50%",
              left: `calc(50% + ${i * 4}px)`,
              transform: "translate(-50%, -50%)",
              zIndex: uniqueAlertLevels.length - i,
            }}
          />
        ))}
        {uniqueIdCount > 1 ? (
          <AlertCount variant="small1Bold">{uniqueIdCount}</AlertCount>
        ) : null}
      </div>
    </Marker>
  )
}
