import { Tab, Tabs, styled } from "@mui/material"
import type React from "react"
import { useEffect, useRef } from "react"
import { useLocation, useMatch } from "react-router-dom"
import { useSearchParams } from "../../hooks/useSearchParams.js"
import { ROUTES } from "../../utils/constants.js"
import { extraColors } from "../../utils/theme.js"
import { PrintTemplate, type PrintHandle } from "../PrintTemplate.js"
import TabPanel from "../TabsPanel.js"
import { Absenteeism } from "./Absenteeism/Absenteeism.js"
import { LocationActions } from "./LocationRisk/Actions/ActionsDropdown.js"
import { LocationRiskDetails } from "./LocationRisk/LocationRiskDetails.js"
import { SiteDetails } from "./SiteDetails/SiteDetails.js"

const TabsContainer = styled("div")({
  marginTop: 12,
})

const TabRow = styled("div")(({ theme }) => ({
  borderBottom: `2px solid ${extraColors.light}`,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: 6,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}))

interface TabProps {
  name: string
  path: string
  component?: React.ReactNode
}

const SITE_DETAILS_PATH = "site-details"
export const LOCATION_DETAILS_TABS = [
  {
    name: "Surrounding Area",
    path: "surrounding-area",
    component: <LocationRiskDetails />,
  },
  {
    name: "Absenteeism",
    path: "absenteeism",
    component: <Absenteeism />,
  },
  {
    name: "Site Details",
    path: SITE_DETAILS_PATH,
    component: <SiteDetails />,
  },
] as const satisfies TabProps[]

export type LocationDetailsTab = (typeof LOCATION_DETAILS_TABS)[number]["path"]

export const isLocationDetailsTab = (
  tab: string | undefined | null
): tab is LocationDetailsTab => LOCATION_DETAILS_TABS.some(t => t.path === tab)

export const LocationDetailsTabs: React.FC = () => {
  const printRef = useRef<PrintHandle>(null)
  const location = useLocation()
  const assetMatch = useMatch(ROUTES.ASSET)
  const { setSearchParams, params, tab } = useSearchParams()

  const activeTabIndex = tab
    ? LOCATION_DETAILS_TABS.findIndex(t => t.path === tab)
    : 0

  useEffect(() => {
    if (!tab) {
      setSearchParams({
        ...params,
        tab: LOCATION_DETAILS_TABS[0].path,
      })
    }
  }, [location.search, params, setSearchParams, tab])

  return (
    <TabsContainer>
      <TabRow>
        <Tabs
          value={activeTabIndex}
          onChange={(_, newTabIndex: number) => {
            setSearchParams({
              ...params,
              tab: LOCATION_DETAILS_TABS[newTabIndex]?.path,
            })
          }}
          aria-label="Location Details"
        >
          {LOCATION_DETAILS_TABS.filter(detailTab =>
            assetMatch ? true : detailTab.path !== SITE_DETAILS_PATH
          ).map(t => (
            <Tab label={t.name} key={t.name} />
          ))}
        </Tabs>
        <LocationActions printRef={printRef} locationId={params.id} />
      </TabRow>
      {LOCATION_DETAILS_TABS.filter(detailTab =>
        assetMatch ? true : detailTab.path !== SITE_DETAILS_PATH
      ).map((detailTab, index) => (
        <TabPanel
          key={detailTab.name}
          value={activeTabIndex}
          index={index}
          ariaLabel={detailTab.path}
        >
          <PrintTemplate ref={printRef}>
            <>{detailTab.component}</>
          </PrintTemplate>
        </TabPanel>
      ))}
    </TabsContainer>
  )
}
