import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material"
import { AssetNotificationType } from "@phc-health/connect-query"
import type React from "react"
import { useCallback, useState } from "react"
import { extraColors } from "../../../../utils/theme"
import {
  ABSENTEEISM_INSIGHT_TYPES,
  SettingGranularity,
  isSettingGranularity,
  useNotificationContext,
} from "../contexts/NotificationContext"
import { useNotificationTypeContext } from "../contexts/NotificationTypeContext"
import { LocationSelectorDialog } from "../LocationSelector/LocationSelectorDialog"

const SelectionSummaryContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: extraColors.subtle,
  padding: 12,
  borderRadius: 3,
  marginTop: 12,
})

export const GlobalSettingsRadioGroup: React.FC<{
  title: string
}> = ({ title }) => {
  const { notificationType } = useNotificationTypeContext()
  const {
    setSelectedAssets,
    selectedAssets,
    nonGlobalAssets,
    settingGranularity,
    setSettingGranularity,
    setDialogSelectedAssets,
  } = useNotificationContext(notificationType)

  const [locationSelectorIsOpen, setLocationSelectorIsOpen] = useState(false)

  const onChangeGranularity = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const granularity = event.currentTarget.value
      if (isSettingGranularity(granularity) && setSettingGranularity) {
        setSettingGranularity(granularity)

        if (granularity == SettingGranularity.GLOBAL) {
          // remove all selected assets in context and dialog when switching to global
          setDialogSelectedAssets(undefined)
          setSelectedAssets(undefined)
        }
      }
    },
    [setDialogSelectedAssets, setSelectedAssets, setSettingGranularity]
  )

  const onOpenSelector = useCallback(
    (newState: boolean) => {
      if (newState && !selectedAssets?.length) {
        setSelectedAssets?.(nonGlobalAssets)
        setDialogSelectedAssets?.(nonGlobalAssets)
      }
      setLocationSelectorIsOpen(newState)
    },
    [
      nonGlobalAssets,
      selectedAssets?.length,
      setDialogSelectedAssets,
      setSelectedAssets,
    ]
  )

  return (
    <FormControl
      sx={{ padding: "20px", border: `1px solid ${extraColors.light}` }}
    >
      <RadioGroup
        value={settingGranularity}
        name="alert levels"
        onChange={onChangeGranularity}
        sx={{ margin: "0 0 0 12px", gap: "6px" }}
      >
        <FormControlLabel
          value={SettingGranularity.GLOBAL}
          control={<Radio />}
          label={`Get notifications for ${title} globally`}
        />
        <FormControlLabel
          value={SettingGranularity.ASSET}
          control={<Radio />}
          label={`Get notifications for ${title} only for my selected locations`}
          onClick={() => setLocationSelectorIsOpen(true)}
        />
      </RadioGroup>
      <SelectionSummary
        notificationType={notificationType}
        setLocationSelectorIsOpen={onOpenSelector}
      />
      <LocationSelectorDialog
        dialogIsOpen={locationSelectorIsOpen}
        setDialogIsOpen={onOpenSelector}
        title="Expert Insights"
        notificationType={notificationType}
      />
    </FormControl>
  )
}

export const SelectionSummary: React.FC<{
  notificationType: AssetNotificationType
  setLocationSelectorIsOpen: (value: boolean) => void
}> = ({ notificationType, setLocationSelectorIsOpen }) => {
  const { nonGlobalAssets, selectedAssets, settingGranularity } =
    useNotificationContext(notificationType)

  const showSummary =
    notificationType === AssetNotificationType.RISK_INCREASE ||
    settingGranularity === SettingGranularity.ASSET ||
    ABSENTEEISM_INSIGHT_TYPES.includes(notificationType)

  return (
    showSummary && (
      <SelectionSummaryContainer>
        <Typography variant="body2Bold" sx={{ color: extraColors.dark }}>
          Receiving notifications for {selectedAssets?.length || 0}/
          {nonGlobalAssets?.length || 0} locations
        </Typography>
        <Typography
          variant="body2Bold"
          onClick={() => setLocationSelectorIsOpen(true)}
          sx={{ color: extraColors.purpleMiddle, cursor: "pointer" }}
        >
          Edit
        </Typography>
      </SelectionSummaryContainer>
    )
  )
}
